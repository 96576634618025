import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import {
  getNameTag,
  getQueueName,
  getValorantRankImage,
} from "@/game-val/utils.mjs";
import LiveTile from "@/shared/LiveTile.jsx";
import { MatchTile as SharedMatchTile } from "@/shared/Profile.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ValLiveTile = ({ profileId }) => {
  const { t } = useTranslation();
  const state = useSnapshot(readState);

  const {
    val: {
      content: { ranks },
      cms: { agents: cmsAgents },
      live: { [profileId]: game },
      profiles,
    },
  } = state;

  const { myTeam, otherTeam, myImage } = useMemo(() => {
    if (!game) return {};
    const teamId = game.players.find(
      (p) => getNameTag(p.gameName, p.tagLine) === profileId,
    )?.teamId;
    if (!teamId) return {};
    const myTeam = [];
    const otherTeam = [];
    let myImage;
    for (const p of game.players) {
      const agent = cmsAgents.find(
        (a) => a.uuid.toLowerCase() === p.agent.uuid.toLowerCase(),
      );
      const agentImage = agent?.images.matchtile.url;
      const playerProfileId = getNameTag(p.gameName, p.tagLine);
      const isMe = playerProfileId === profileId;
      if (isMe) {
        myImage = agentImage;
      }
      const playerProfile = profiles[playerProfileId];
      const competitiveTier = playerProfile?.valorantProfile?.latestTier;
      const playerRank = ranks.find((r) => r.position === competitiveTier);
      const rank = competitiveTier ? playerRank?.rank : null;
      const tier = competitiveTier ? playerRank?.tier : null;
      const rankImg = getValorantRankImage({ tier, rank });
      const playerDetails = {
        id: getNameTag(p.gameName, p.tagLine),
        ImgComponent: (
          <img src={agentImage} className={isMe ? "user" : "teammate"} />
        ),
        RankComponent: <img src={rankImg} />,
      };
      if (p.teamId === teamId) {
        myTeam.push(playerDetails);
      } else {
        otherTeam.push(playerDetails);
      }
    }

    return { myTeam, otherTeam, myImage };
  }, [cmsAgents, game, profileId, profiles, ranks]);

  return game ? (
    <SharedMatchTile
      height={128}
      match={game}
      matchRoute={`/valorant/in-game/${profileId}`}
      isLive
    >
      <LiveTile
        title={t("common:liveGame", "Live Game")}
        queueType={t(`val:queues.${game.queue}`, getQueueName(game.queue))}
        startTime={game.gameStart}
        myTeam={myTeam}
        otherTeam={otherTeam}
        image={myImage}
      />
    </SharedMatchTile>
  ) : null;
};

export default ValLiveTile;
