import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import type { ValorantPlayerAgentStats } from "@/data-models/valorant-player-agent-stats.mjs";
import { formatAgentStats } from "@/game-val/agents-utils.mjs";
import { GENERAL_COLS, getAgentRow } from "@/game-val/components/Agents";
import {
  getProfileStatsParams,
  updateProfileTabsParams,
} from "@/game-val/profile-utils.mjs";
import SelectAct from "@/game-val/SelectAct.jsx";
import SelectMode from "@/game-val/SelectMode.jsx";
import { getPlatformPath, useConsoleState } from "@/game-val/utils/console.mjs";
import ContentContainer from "@/shared/ContentContainer.jsx";
import type { Cell, Column } from "@/shared/DataTable.jsx";
import DataTable from "@/shared/DataTable.jsx";
import FilterBar from "@/shared/FilterBar.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Filters = () => {
  const { searchParams, currentPath } = useRoute();
  const {
    val: {
      content: { acts },
    },
  } = useSnapshot(readState);

  const { isConsole } = useConsoleState();
  const { actId, queue } = getProfileStatsParams(searchParams, acts);

  const updateFilters = useCallback(
    (key, val) => {
      updateProfileTabsParams(
        { [key]: val, searchParams, currentPath, acts },
        "agents",
      );
    },
    [searchParams, currentPath, acts],
  );

  return (
    <FilterBar>
      <SelectMode
        selected={queue}
        isConsole={isConsole}
        onChange={(v) => updateFilters("queue", v)}
      />
      <SelectAct
        selected={actId}
        onChange={(v) => updateFilters("actId", v)}
        type="id"
      />
    </FilterBar>
  );
};

const Table = ({ puuid }) => {
  const { t } = useTranslation();
  const { searchParams } = useRoute();
  const { isConsole } = useConsoleState();
  const platformPath = getPlatformPath(isConsole);

  const {
    val: {
      cms,
      content: { acts },
      playerAgentStats,
    },
  } = useSnapshot(readState);

  const cols: Column[] = GENERAL_COLS(t);

  const { actId, queue } = getProfileStatsParams(searchParams, acts);

  const agentsStats: ValorantPlayerAgentStats =
    playerAgentStats[puuid]?.[actId]?.[platformPath]?.[queue];

  const rows: Cell[][] | null = useMemo(() => {
    if (!agentsStats || agentsStats instanceof Error) return null;
    const totalMatches = Object.values(agentsStats).reduce(
      (a, b) => a + b.matchesPlayed,
      0,
    );
    const mappedData = (cms?.agents || [])
      .map((agent) => {
        const { uuid, key, name, class: Class } = agent;
        const stats = agentsStats.find(
          (s) => s.agent.uuid.toLowerCase() === uuid.toLowerCase(),
        );

        if (!stats) return null;

        const {
          kills,
          score,
          deaths,
          assists,
          roundsPlayed,
          matchesWon: wins,
          matchesPlayed: matches,
        } = stats;

        const formattedStats = formatAgentStats({
          key,
          name,
          Class,
          kills,
          deaths,
          assists,
          wins,
          score,
          roundsPlayed,
          matches,
          ability1Casts: 0,
          ability2Casts: 0,
          grenadeCasts: 0,
          ultimateCasts: 0,
          totalMatches,
          agent,
        });
        return getAgentRow(formattedStats);
      })
      .filter((o) => o);

    return mappedData;
  }, [cms?.agents, agentsStats]);

  return (
    <DataTable indexCol cols={cols} rows={rows} sortCol={1} sortDir="DESC" />
  );
};

export default function ProfileAgents({ puuid }) {
  return (
    <ContentContainer className="flex column gap-sp-4">
      <Filters />
      <Table puuid={puuid} />
    </ContentContainer>
  );
}
