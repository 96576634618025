import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import {
  getProfileStatsParams,
  updateProfileTabsParams,
  WEAPON_TAB,
} from "@/game-val/profile-utils.mjs";
import SelectAct from "@/game-val/SelectAct.jsx";
import SelectMode from "@/game-val/SelectMode.jsx";
import SelectWeaponType from "@/game-val/SelectWeaponType.jsx";
import { calcAvg, calcHeadshotPercent } from "@/game-val/utils.mjs";
import { getPlatformPath, useConsoleState } from "@/game-val/utils/console.mjs";
import { WeaponsCol } from "@/game-val/Weapons.jsx";
import ContentContainer from "@/shared/ContentContainer.jsx";
import type { Cell, Column } from "@/shared/DataTable.jsx";
import DataTable from "@/shared/DataTable.jsx";
import FilterBar from "@/shared/FilterBar.jsx";
import WeaponHitItem from "@/shared-fps/WeaponHitItem.jsx";
import { calcRate } from "@/util/helpers.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Filters = () => {
  const { searchParams, currentPath } = useRoute();
  const {
    val: {
      content: { acts },
    },
  } = useSnapshot(readState);
  const {
    actId,
    queue,
    type = "all",
  } = getProfileStatsParams(searchParams, acts, WEAPON_TAB);
  const { isConsole } = useConsoleState();

  const updateFilters = useCallback(
    (key, val) => {
      updateProfileTabsParams(
        { [key]: val, searchParams, currentPath, acts },
        WEAPON_TAB,
      );
    },
    [searchParams, currentPath, acts],
  );

  return (
    <FilterBar>
      <SelectMode
        selected={queue}
        isConsole={isConsole}
        onChange={(v) => updateFilters("queue", v)}
      />
      <SelectWeaponType
        selected={type}
        onChange={(v) => updateFilters("type", v)}
      />
      <SelectAct
        selected={actId}
        onChange={(v) => updateFilters("actId", v)}
        type="id"
      />
    </FilterBar>
  );
};

const Table = ({ puuid }) => {
  const { t } = useTranslation();
  const { searchParams } = useRoute();
  const { isConsole } = useConsoleState();
  const platformPath = getPlatformPath(isConsole);

  const {
    val: {
      cms: { weapons },
      content: { acts },
      playerWeaponStats,
    },
  } = useSnapshot(readState);

  const {
    actId,
    queue,
    type = "all",
  } = getProfileStatsParams(searchParams, acts, WEAPON_TAB);

  const currentStats =
    playerWeaponStats[puuid]?.[actId]?.[platformPath]?.[queue];

  const cols: Column[] = [
    {
      display: t("val:weapon", "Weapon"),
      isStat: false,
    },
    {
      display: t("val:stats.killConversion", "Kill Conversion"),
      isStat: true,
    },
    {
      display: t("common:stats.totalKills", "Total Kills"),
      isStat: true,
    },
    {
      display: t("val:stats.altKills", "Alt. Kills"),
      isStat: true,
    },
    {
      display: t("val:stats.avgDamage", "Avg. Damage"),
      isStat: true,
    },
    {
      display: t("val:stats.headshotPercent", "Headshot %"),
      isStat: true,
    },
    {
      display: t("val:stats.hits", "Hits"),
      isStat: false,
    },
  ];

  const rows: Cell[][] | null = useMemo(() => {
    if (!currentStats || currentStats instanceof Error) return null;
    return weapons
      .map((metaInfo) => {
        const stats = currentStats.find(
          (w) => w.weapon.uuid.toLowerCase() === metaInfo.uuid.toLowerCase(),
        );

        if (!stats || !metaInfo || (type !== "all" && metaInfo?.type !== type))
          return null;

        const {
          kills,
          headshots,
          bodyshots,
          legshots,
          altKills,
          damagePerRound,
          roundsPlayed,
          matchesPlayed,
        } = stats;

        const killsPerUse = calcAvg(kills, roundsPlayed);
        const avgDamage = calcRate(damagePerRound, matchesPlayed, 1);
        const headshotPercent = calcHeadshotPercent({
          headshots,
          bodyshots,
          legshots,
        });

        return [
          {
            display: (
              <div className={WeaponsCol()}>
                {metaInfo.svgImage.url && (
                  <img src={metaInfo.svgImage.url} alt={metaInfo.name} />
                )}
                <span className="type-body2">{metaInfo.name}</span>
              </div>
            ),
            link: `/valorant/weapons/${metaInfo.key}`,
            value: metaInfo.name,
          },
          {
            display: getLocaleString(killsPerUse, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            value: killsPerUse,
          },
          {
            display: getLocaleString(kills, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }),
            value: kills,
          },
          {
            display: getLocaleString(altKills, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }),
            value: altKills,
          },
          {
            display: getLocaleString(avgDamage, {
              maximumFractionDigits: 1,
              minimumFractionDigits: 1,
            }),
            value: avgDamage,
          },
          {
            display: getLocaleString(headshotPercent, {
              maximumFractionDigits: 1,
              minimumFractionDigits: 1,
            }),
            value: headshotPercent,
          },
          {
            display: (
              <WeaponHitItem
                headshots={headshots}
                bodyshots={bodyshots}
                legshots={legshots}
                hasLegshots
              />
            ),
            value: headshotPercent,
          },
        ];
      })
      .filter(Boolean);
  }, [currentStats, type, weapons]);

  return (
    <DataTable cols={cols} rows={rows} indexCol sortCol={1} sortDir="DESC" />
  );
};

export default function ProfileWeapons({ puuid }) {
  return (
    <ContentContainer className="flex column gap-sp-4">
      <Filters />
      <Table puuid={puuid} />
    </ContentContainer>
  );
}
