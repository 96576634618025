import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { getWinRateColor, kdaColor } from "@/game-val/utils.mjs";
import SharedMatchHistoryHeader from "@/shared/MatchHistoryHeader.jsx";
import { calcRate } from "@/util/helpers.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";

const Tooltip = styled("div")`
  min-width: var(--sp-22_5);
  display: flex;
  .tooltip-text {
    color: var(--shade3);
  }
  .tooltip-kd {
    margin-right: var(--sp-4);
  }
`;

const AgentToolTip = ({
  statLeft,
  statLeftColor,
  leftDescription,
  statRight,
  rightDescription,
}) => {
  return (
    <Tooltip className="type-caption--bold">
      <div className="tooltip-kd">
        <p style={{ color: statLeftColor }}>{statLeft}</p>
        <p className=" tooltip-text">{leftDescription}</p>
      </div>
      <div>
        <p>{statRight}</p>

        <p className="tooltip-text">{rightDescription}</p>
      </div>
    </Tooltip>
  );
};

function MatchHistoryHeader({ matchList, cmsAgents }) {
  const { t } = useTranslation();

  const { statLines, topThree, matchBarGraph } = useMemo(() => {
    const perAgentStats = matchList.reduce((acc, match) => {
      const agentId = match.agent.uuid;
      if (!acc[agentId])
        acc[agentId] = {
          kills: 0,
          deaths: 0,
          score: 0,
          roundsPlayed: 0,
          wins: 0,
          losses: 0,
          ties: 0,
          matches: 0,
        };
      const stats = acc[agentId];
      stats.kills += match.kills;
      stats.deaths += match.deaths;
      stats.score += match.score;
      stats.roundsPlayed += match.roundsPlayed;
      stats.wins += match.won ? 1 : 0;
      stats.losses += match.won ? 0 : 1;
      stats.matches += 1;
      return acc;
    }, {});
    const mainStats = Object.values(perAgentStats).reduce(
      (acc, agentStats) => {
        acc.kills += agentStats.kills;
        acc.deaths += agentStats.deaths;
        acc.score += agentStats.score;
        acc.roundsPlayed += agentStats.roundsPlayed;
        acc.wins += agentStats.wins;
        acc.losses += agentStats.losses;
        acc.matches += agentStats.matches;

        return acc;
      },
      {
        kills: 0,
        deaths: 0,
        score: 0,
        roundsPlayed: 0,
        wins: 0,
        losses: 0,
        ties: 0,
        matches: 0,
      },
    );
    const kd = calcRate(mainStats.kills, mainStats.deaths, 2);
    const winRate = calcRate(mainStats.wins, mainStats.matches);

    const score = calcRate(mainStats.score, mainStats.roundsPlayed, 1);

    const topAgents = Object.entries(perAgentStats)
      .sort(([, a], [, b]) => b.matches - a.matches)
      .slice(0, 3);

    const topThree = topAgents.map(([agentId, agentInfo]) => {
      const agent = cmsAgents.find(
        (a) => a.uuid.toLowerCase() === agentId.toLowerCase(),
      );
      const agentKey = agent?.key;

      const agent_image = agent?.images.matchtile.url;
      const winRate = calcRate(
        agentInfo.wins || 0,
        (agentInfo.matches || 0) + (agentInfo.ties || 0),
      );
      const statColor = getWinRateColor(winRate);

      if (!agentInfo) {
        return {
          key: agentKey,
          iconUrl: agent_image,
          statColor: statColor,
          stat: winRate.toLocaleString(getLocale(), {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: "percent",
          }),
          iconTooltip: null,
        };
      }

      const losses = agentInfo.matches - agentInfo.wins;
      const kd = calcRate(agentInfo.kills, agentInfo.deaths, 2);
      const tooltipInfo = {
        statLeft: kd || "-",
        leftDescription: t("common:stats.kd", "KD"),
        statLeftColor: kdaColor(kd),
        statRight: t("common:stats.winsDashLosses", "{{wins}}W - {{losses}}L", {
          wins: agentInfo.wins || 0,
          losses: losses || 0,
        }),
        rightDescription: t("common:stats.record", "Record"),
      };
      const iconTooltip = AgentToolTip(tooltipInfo);

      return {
        key: agentKey,
        iconUrl: agent_image,
        statColor: statColor,
        stat: winRate.toLocaleString(getLocale(), {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          style: "percent",
        }),
        iconTooltip: iconTooltip,
      };
    });

    const statLines = [
      {
        stat: t("common:stats.winsLosses", "{{wins}}W {{losses}}L", {
          wins: mainStats.wins,
          losses: mainStats.matches - mainStats.wins - mainStats.ties,
        }),
        description: t("val:lastNMatches", "Last {{matches}}", {
          matches: mainStats.matches,
        }),
      },
      {
        stat: winRate.toLocaleString(getLocale(), {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          style: "percent",
        }),
        description: t("common:stats.wr", "Winrate"),
        statColor: getWinRateColor(winRate),
      },
      {
        stat: kd ? kd : "- - -",
        description: t("common:stats.kd", "KD"),
        statColor: "rgb(151, 141, 135)",
      },
      {
        stat: score ? score : 0,
        description: t("common:stats.avgScore", "Avg. Score"),
      },
    ];

    const matchBarGraph = {
      won: mainStats.wins || 0,
      lost: mainStats.matches - mainStats.wins,
      draw: mainStats.ties,
    };

    return { statLines, topThree, matchBarGraph };
  }, [matchList, cmsAgents, t]);

  if (matchList.length === 0) {
    return null;
  }

  return (
    <SharedMatchHistoryHeader
      statColumns={statLines}
      IconList={topThree}
      matchBarGraph={matchBarGraph}
      padding={"var(--sp-5)"}
    />
  );
}

export default memo(MatchHistoryHeader);
